<template>
    <div class="SummaryManager-container">
        <CSTabBar :tabbar="tabBar"
                  :checked-tab="checkedTaBarNumer"
                  @changeTabBar="changeTabBar">

        </CSTabBar>
        <template v-if="checkedTaBarNumer == 1">
            <ChargeWater></ChargeWater>
        </template>
        <template v-if="checkedTaBarNumer == 2">
            <ElectricityFees></ElectricityFees>
        </template>
    </div>
</template>

<script>
    import CSTabBar from "@/components/common/CSTabBar";
    import ChargeWater from "@/views/WaterElectricityMeter/PoolBill/Summary/chargeWater";
    import ElectricityFees from "@/views/WaterElectricityMeter/PoolBill/Summary/electricityFees";
    export default {
        name: "SummaryManager",
        components: {
            CSTabBar,
            ChargeWater,
            ElectricityFees
        },
        created() {

        },
        data() {
            return {
                tabBar: {
                    1: "水费",
                    2: "电费"
                },
                checkedTaBarNumer: 1
            }
        },
        methods: {
            changeTabBar(index) {
                this.checkedTaBarNumer = index;
            }
        }
    }
</script>

<style scoped>

</style>